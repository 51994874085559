* {
	box-sizing: border-box;
}

body,
html {
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin: 0px;
	padding: 0px;
	font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}

button {
	cursor: pointer;
}

button:focus {
	outline: none;
}

@keyframes blink {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
}

.blinking {
	animation: blink 1s infinite;
}
